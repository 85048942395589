.listing {
  // height: 400px;
  width: 100%;
  // background-color: yellow;
  background: transparent;
  // border: 1px solid red;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // gap: 2rem;
  // margin: 2rem 0px;
}
.info_skilled {
  width: 100%;
  // background-color: blue;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 30px;
  // border: 2px solid gray;
}
.just_info {
  width: 100%;
  // display: flex;
  // flex-direction: column;
  // background-color: green;

  height: auto;
  padding: 0px;
  // justify-content: center;
  // align-items: center;
}
.type {
  height: auto;
  width: 100%;
  // background-color: #f15b24;
  padding: 5px 18px;
  border-radius: 0px 7px 7px 0px;
}
.geography {
  // background-color: orange;
  display: flex;
  width: 100%;
  height: auto;
  padding-left: 20px;
  justify-content: center;
}
.s_all {
  width: 100%;
  // background-color: tomato;
  height: auto;
  // padding-right: 1.5rem;
  // border: 1px solid green;
  text-align: center;
  // float: right;
}

@media only screen and (max-width: 1100px) {
  .just_info {
    font-size: 40px;
  }
  // .type {
  //   width: 22%;
  //   padding: 2px 12px;
  // }
  // .geography {
  //   width: 58%;
  // }
}
