.img-container{
    width: 100%;
    // background-color: yellow;
    // border: 1px solid yellow;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.image-slider{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    // background-color: gray;
}
.slider-img{
    object-fit: fill;
}
.search-bar{
    // height: 50px;
    // width: 70%;
    // border: 1px solid black;
    // background-color: #ffffff;
    position: absolute;
    bottom: 12%;
    border-radius: 50px 10px 10px 50px;
    // padding: 4px 10px;
    display: flex;
    align-items: center;
}
    .search-input{
        width: 96%;
        height: 36px;
        font-size: 18px;
        border-radius: 50px 10px 10px 50px;
        outline: none;
        border: none;
        padding-left: 8px;
        // background-color: red;
    }

@media only screen and (max-width: 646px){
    .search-input{
        height: 24px;
        font-size: 12px;
        // background-color: yellow;
        padding-left: 6px;
    }
}