table,
td,
th {
  font-size: 6px;
}
.job_title_btn {
  background-color: #f15b24;
  font-size: 40px;
}
.job_apply_title_section {
  background-color: transparent;
  display: flex;
  justify-content: space-evenly;
  // position: absolte;
}

.job_apply_title_section--title {
  // background: radial-gradient(
  //   circle at top left,
  //   transparent 20px,
  //   #f15b24 20.5px
  // );
  background: radial-gradient(
    circle at top left,
    transparent 23px,
    #f15b24 25px
  );
  height: 1.7rem;
}
@media only screen and (max-width: 646px) {
  .job_apply_title_section--title {
    background: radial-gradient(
      circle at top left,
      transparent 20px,
      #f15b24 20.5px
    );
    height: 100%;
  }
}
